import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpg";
import review2 from "../../assets/review2.jpg";
import review3 from "../../assets/review3.jpg";
import review4 from "../../assets/review4.jpg";
import review5 from "../../assets/review5.jpg";
import review6 from "../../assets/review6.jpg";
import like from "../../assets/like.svg";
import user from "../../assets/user.png";
import { useState } from "react";

export const Reviews = () => {
  const [showReview, setShowReview] = useState(false);
  return (
    <>
      <p className="reviews__title">রেটিং এবং পর্যালোচনা</p>
      <div className="reviews__wrapper">
        <div>
          <p className="reviews__score">4.9</p>
          <div className="star__wrapper">
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGrey} alt="grey star" className="green__star" />
          </div>
          <p className="user__wrapper">
            সাধারণ
            <img src={user} alt="user icon" width={16} height={16} />
          </p>
          <p className="reviews__number">5133</p>
        </div>
        <div>
          <ul className="lines__list">
            <li className="lines__item">
              <p className="numbers">5</p>
              <div className="first__line desktop_one">
                <div className="first__line--green"></div>
              </div>
            </li>
            <li className="lines__item">
              <p className="numbers">4</p>
              <div className="first__line desktop_two">
                <div className="second__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">3</p>
              <div className="first__line desktop_three">
                <div className="third__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">2</p>
              <div className="first__line desktop_four">
                <div className="fourth__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">1</p>
              <div className="first__line desktop_five">
                <div className="fourth__line--green"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review1}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">অর্জুন চ্যাটার্জি</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">21.04.2024</p>
            </div>
            {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
          </div>
          <p className="item__text">
            ওয়াও, এটা সেরা প্রকল্প যা আমি কখনো দেখেছি! তা দ্রুত 20,000 বোনাস
            পরিষ্কার হয়েছে, স্লটে প্রবেশ করে সরাসরি 3 বোনাস পেয়েছি! তা আমি তা
            মুহূর্তে কার্ডে টাকা তুলেছি! এটি সেরা প্রকল্প, এখন আমি এটে অনেক
            খেলছি!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">পাগল সময় ক্যাসিনো</p>
            <p className="review__description">
              অর্জুন চ্যাটার্জি, আপনার পর্যালোচনা জন্য ধন্যবাদ! আমাদের জন্য
              অত্যন্ত গুরুত্বপূর্ণ যে আমাদের গ্রাহকগণ আমাদের প্রকল্প থেকে
              সন্তুষ্ট হন। আমাদের দল সর্বোত্তম আনন্দ অর্জন করার জন্য প্রতিটি
              প্রয়াস করে!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review2}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">প্রিয়া দাস</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGrey}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">20.04.2024</p>
            </div>
            {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
          </div>
          <p className="item__text">
            বন্ধুরা আবার একটি বোমা প্রকল্প তৈরি করেছে, যার সাথে আবারও কোন
            সামর্থ্য নেই। প্রতিযোগীদের মধ্যে শ্রেষ্ঠ। মজাদার স্লট এবং হাঁটুর
            বোনাস। 1500 BDT ডিপোজিট করেছিলাম, ব্যাংক কার্ডে 9300 তাকা উত্তোলন
            করেছিলাম! বাজার উপেক্ষ হতে পারে না, গর্বিত প্রকল্প!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">পাগল সময় ক্যাসিনো</p>
            <p className="review__description">
              প্রিয়া দাস, আপনার সাক্ষাতকারের জন্য আপনাকে ধন্যবাদ। আমরা সর্বদা
              আমাদের প্রকল্পের উন্নতির উদ্যোগে নিরন্তর কাজ করছি, আপনার পছন্দ
              হওয়াটা আমাদের খুব আনন্দে আনে।
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review3}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">রাহুল ঘোষ</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">17.04.2024</p>
            </div>
            {/* <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div> */}
          </div>
          <p className="item__text">
            সাধারণত আমি এই সব ক্যাসিনোগুলি বিশ্বাস করি না! তবে এই অ্যাপটি - এটা
            কিছু নয়! 300 BDT প্রয়োজন ছিল এবং 15 মিনিটে 3000 উঠিয়ে নিলাম। আমি
            আরও খেলব এবং টাকা আদায় করব!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">পাগল সময় ক্যাসিনো</p>
            <p className="review__description">
              রাহুল ঘোষ, আপনার সুখবর জানার জন্য ধন্যবাদ! খেলুন এবং আনন্দ করুন!
            </p>
          </div>
        </div>
      </div>
      {showReview && (
        <>
          <div className="item__wrapper">
            <img
              src={review4}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">মিরা মুখার্জী</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">15.04.2024</p>
                </div>
                {/* <div className="item__likes">
                <img src={like} alt="" />
                <p className="item__like">0</p>
              </div> */}
              </div>
              <p className="item__text">
                আমি আশা করতাম না যে বন্ধুরা এমন একটি অসাধারণ প্রকল্প তৈরি করবে!
                সম্মান এবং শ্রদ্ধা! আমার জীবনের সবচেয়ে উদার এবং প্রদানকারী
                ক্যাসিনো! আমি সুখের অবাকে!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">পাগল সময় ক্যাসিনো</p>
                <p className="review__description">
                  মিরা মুখার্জী, আপনার পর্যালোচনার জন্য ধন্যবাদ। আমাদের
                  প্রকল্পটি মানুষদের জন্য ভাবে তৈরি করা হয়েছিল। আমরা সর্বদা
                  আপনাদের খুশি করার চেষ্টা করব। আপনার জন্য শুভকামনা!
                </p>
              </div>
            </div>
          </div>
          {/* <div className="item__wrapper">
            <img
              src={review5}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Максим Ефремов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">11.03.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                Насыпало за 5 дней игры очень круто! Спасибо за офигенное
                казино!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Казино</p>
                <p className="review__description">
                  Максим Ефремов, спасибо за отзыв! Нам очень важно, чтобы наши
                  клиенты получали удовольствие от нашего проекта.
                </p>
              </div>
            </div>
          </div>
          <div className="item__wrapper">
            <img
              src={review6}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Олег Мартынов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">09.03.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                У меня нет слов. С 500 рублей поднял 7000 за пару часов ;))
                Давно мне так не фартило. Лучшее казино в которое я играл!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Казино</p>
                <p className="review__description">
                  Олег Мартынов, благодарим вас за положительный отзыв.
                  Стараемся удовлетворять наших клиентов!
                </p>
              </div>
            </div>
          </div> */}
        </>
      )}
      <button
        type="button"
        className="reviews__button"
        onClick={() => {
          setShowReview((prevState) => !prevState);
        }}
      >
        {showReview ? "মন্তব্য লুকান" : "সমস্ত পর্যালোচনা"}
      </button>
    </>
  );
};
