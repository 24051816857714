import logo from "../../assets/logo.png";
import item1 from "../../assets/item1.jpg";
import item2 from "../../assets/item2.jpg";
import item3 from "../../assets/item3.jpg";
import blueStar from "../../assets/starBlue.png";
import greyStar from "../../assets/starGrey.png";
import user from "../../assets/user.png";
import { useEffect, useState } from "react";
import { Reviews } from "../Reviews/Reviews";
import { New } from "../New/New";
import { Additional } from "../Additional/Additional";
import { ColorRing } from "react-loader-spinner";
import UAParser from "ua-parser-js";
// import { useReactPWAInstall } from "react-pwa-install";

export const Main = ({
  handleClick,
  animating,
  setAnimating,
  megabyte,
  supported,
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [bgdataLayer, setBgdataLayer] = useState([]);

  // const { supported, isInstalled } = useReactPWAInstall();

  const creative_id = localStorage.getItem("creative_id");
  const offer = localStorage.getItem("offer");
  const ad_campaign_id = localStorage.getItem("ad_campaign_id");
  const source = localStorage.getItem("source");
  const creo_id = localStorage.getItem("creo_id");
  const external_id = localStorage.getItem("external_id");
  const site_id = localStorage.getItem("site_id");
  const cost = localStorage.getItem("cost");
  const sub_id_1 = localStorage.getItem("sub_id_1");
  const sub_id_2 = localStorage.getItem("sub_id_2");
  const sub_id_3 = localStorage.getItem("sub_id_3");
  const id = localStorage.getItem("id");

  const ifWebView = () => {
    // const ua = new UAParser();
    // const urlParams = new URLSearchParams(window.location.search);

    // if (
    //   ua.getBrowser().name !== "Chrome" &&
    //   ua.getBrowser().name !== "Yandex"
    // ) {
    //   // alert(window.location.hostname);
    //   // alert("test8")
    //   var lnk = document.getElementById("r");
    //   // lnk.setAttribute("href", `intent://${window.location.hostname}/?#Intent;scheme=https;package=com.android.chrome;end;`);
    //   lnk.setAttribute(
    //     "href",
    //     `intent://${window.location.hostname}/?sub_id_1=${
    //       sub_id_1 ? sub_id_1 : ""
    //     }&sub_id_2=${sub_id_2 ? sub_id_2 : ""}&sub_id_3=${
    //       sub_id_3 ? sub_id_3 : ""
    //     }#Intent;scheme=https;package=com.android.chrome;end;`
    //   );

    //   lnk.click();

    //   // lnk.click();
    //   if (ua.getBrowser().name == "Chrome WebView") {
    //     //goLink();
    //   }
    // } else {
    handleClick();
    // }
  };

  // useEffect(() => {
  //   // Load the script asynchronously
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://api.imotech.video/ad/events.js?pixel_id=904399210548106496";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   // Define bgdataLayer globally after script is loaded
  //   window.addEventListener("load", () => {
  //     window.bgdataLayer = window.bgdataLayer || [];
  //     setBgdataLayer(window.bgdataLayer);
  //   });

  //   return () => {};
  // }, []);

  // const sendDataToBgDataLayer = () => {
  //   // Push data to bgdataLayer
  //   function bge() {
  //     bgdataLayer.push(arguments);
  //   }
  //   bge("init", "904399210548106496");
  // };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const linkToOffer = () => {
    // setAnimating(true);
    setTimeout(() => {
      if (id === "1059") {
        window.location.href = `https://arumo.top/MKJSYLcy?creative_id=${creative_id}&source=${source}`;
      } else if (id === "1056") {
        window.location.href = `https://arumo.top/hzB6Gs4j?creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&source=${source}`;
      } else if (id === "1058") {
        window.location.href = `https://arumo.top/Rkmqj1SR?external_id=${external_id}&creo_id=${creo_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&sub_id_1=${sub_id_1}`;
      } else if (id === "1057") {
        window.location.href = `https://arumo.top/wpnqLDfV?external_id=${external_id}&creative_id=${creative_id}&ad_campaign_id=${ad_campaign_id}&site_id=${site_id}&cost=${cost}`;
      } else {
        window.location.href = `https://arumo.top/MKJSYLcy?creative_id=${creative_id}&source=${source}`;
      }
    }, 80);
  };
  return (
    <div className="main__top">
      <main className="main">
        <div className="main__wrapper">
          <img src={logo} alt="logo" className="logo" />
          <div className="main__wrap">
            <p className="main__title">পাগল সময় ক্যাসিনো</p>
            <div className="main__tags">
              <p className="main__tag">পাগল সময় ক্যাসিনো</p>
              <p className="main__tag">ক্যাসিনো</p>
            </div>
            <p className="years">18+</p>
          </div>
          <div className="desktop__info">
            <div className="top__wrapper">
              <img src={blueStar} alt="safety" width={14} height={14} />
              <p className="top__text">সম্পাদকদের পছন্দ</p>
            </div>
            <div className="bottom__wrapper">
              <div className="greyStars__wrapper">
                <img src={greyStar} alt="grey star" width={10} height={10} />
                <img src={greyStar} alt="grey star" width={10} height={10} />
                <img src={greyStar} alt="grey star" width={10} height={10} />
                <img src={greyStar} alt="grey star" width={10} height={10} />
                <img src={greyStar} alt="grey star" width={10} height={10} />
              </div>
              <p className="bottom__text">5133</p>
              <img src={user} alt="" />
            </div>
          </div>
        </div>
        <div className="app__info">
          <ul className="app__list">
            <li className="app__item">
              <div className="app__content">
                <div className="app__wrapper">
                  <p className="app__title">4.9</p>
                  <svg
                    width={14}
                    enableBackground="new 0 0 24 24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#030303"
                  >
                    <g fill="none">
                      <path d="M0,0h24v24H0V0z"></path>
                      <path d="M0,0h24v24H0V0z"></path>
                    </g>
                    <path d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"></path>
                  </svg>
                </div>
                <p className="app__description">মূল্যায়ন</p>
              </div>
            </li>
            <li className="app__item">
              <div className="app__content">
                <p className="app__title">
                  <svg
                    viewBox="0 0 18 18"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    fill="#030303"
                  >
                    <g transform="translate(21.552 22.5) rotate(180)">
                      <path
                        transform="translate(7.552 7.652)"
                        d="M.625,0h8.75A.68.68,0,0,1,10,.723a.68.68,0,0,1-.625.723H.625A.68.68,0,0,1,0,.723.68.68,0,0,1,.625,0Z"
                        data-name="Path 288"
                      ></path>
                      <g transform="translate(17.552 20.797) rotate(180)">
                        <path
                          d="M0,0H9.666V9.666H0Z"
                          fill="none"
                          data-name="Path 289"
                        ></path>
                        <path
                          transform="translate(-4.408 -3.203)"
                          d="M8.752,4.642V11.81L5.536,8.678a.677.677,0,0,0-.936,0,.627.627,0,0,0,0,.9l4.343,4.229a.669.669,0,0,0,.929,0l4.343-4.229a.627.627,0,0,0,0-.9.669.669,0,0,0-.929,0L10.07,11.81V4.642a.659.659,0,0,0-1.318,0Z"
                          data-name="Path 290"
                        ></path>
                      </g>
                      <rect
                        transform="translate(4.552 5.5)"
                        width="16"
                        height="16"
                        rx="2"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                        data-name="Rectangle 123"
                      ></rect>
                    </g>
                  </svg>
                </p>
                <p className="app__description">3.2MB</p>
              </div>
            </li>
            <li className="app__item">
              <div className="app__content">
                <p className="app__title">18+</p>
                <p className="app__description">বয়স</p>
              </div>
            </li>
            <li className="app__item">
              <div className="app__content">
                <p className="app__title">127544</p>
                <p className="app__description">ইনস্টল করুন</p>
              </div>
            </li>
          </ul>
        </div>
        {animating === true ? (
          <div className="lines__wrapper">
            {megabyte === "Installing..." ? (
              <p className="megabytes">{megabyte}</p>
            ) : (
              <p className="megabytes">{megabyte} MB / 3.2 MB</p>
            )}
            <div className="greyLine">
              <div className="greenLine"></div>
            </div>
          </div>
        ) : animating === "done" ? (
          supported ? (
            <a href="/" target="_blank" className="main__button pwa__link">
              খেলা
            </a>
          ) : (
            <button
              type="button"
              className="main__button"
              onClick={linkToOffer}
            >
              খেলা
            </button>
          )
        ) : (
          <button
            type="button"
            className="main__button"
            onClick={() => {
              ifWebView();
              // sendDataToBgDataLayer();
              // const ad = document.getElementById("sendpixel");
              // ad.click();
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <ColorRing
                visible={true}
                height="27"
                width="27"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"]}
              />
            ) : (
              "ইনস্টল করুন"
            )}
            {/* {supported() && !isInstalled() ? "Install" : "Play"} */}
          </button>
        )}
        <div className="main__thumb">
          <img
            src={item1}
            alt="app view"
            height={210}
            className="main__images"
          />
          <img
            src={item2}
            alt="app view"
            height={210}
            className="main__images"
          />
          <img
            src={item3}
            alt="app view"
            height={210}
            className="main__images"
          />
        </div>
        <p className="main__about">এই অ্যাপ সম্পর্কে</p>
        <p className="main__item--one">
          পাগল সময় ক্যাসিনো একটি সুপার অ্যাপ্লিকেশন যা ক্যাসিনো বিশ্বের বিভিন্ন
          ধরণের গেম এবং নতুন পণ্য রয়েছে!
        </p>
        <ul
          className={
            showDescription ? "main__list" : "main__list main__list--blur"
          }
        >
          <li className="main__item">
            বাংলাদেশের সবচেয়ে নির্ভরযোগ্য এবং আইনি ক্যাসিনো 2024!
          </li>
          {showDescription && (
            <>
              <li className="main__item">
                তাত্ক্ষণিক জমা এবং তহবিল উত্তোলন, কোন কমিশন নেই
              </li>
              <li className="main__item">সর্বনিম্ন আমানত মাত্র 500 BDT</li>
              <li className="main__item">
                আপনার সমস্ত প্রিয় স্লট এক জায়গায়
              </li>
              <li className="main__item">ঘন ঘন টুর্নামেন্ট এবং লটারি</li>
              <li className="main__item">24/7 প্রযুক্তিগত সহায়তা</li>
              <li className="main__item">
                বাংলাদেশের সব ক্যাসিনোর মধ্যে সেরা প্রকল্প!
              </li>
              <p
                className="main__item"
                style={{ marginLeft: "-15px", marginBottom: "20px" }}
              >
                নতুন ক্যাসিনোতে খেলুন - সহজে টাকা পান!
              </p>
              <p className="main__item" style={{ marginLeft: "-15px" }}>
                নতুন খেলোয়াড়দের জন্য রয়েছে ৫০,০০০ টাকা বোনাস!
              </p>
              <li className="main__item">
                1,000,000 BDT ড্রতে বিনামূল্যে অংশগ্রহণ!
              </li>
              <li className="main__item">
                150 FS (ফ্রি স্পিন) - ভাগ্যের চাকা ঘুরান
              </li>
              <li className="main__item">
                খেলোয়াড়দের বিভিন্ন পরিষেবার জন্য অর্থ প্রদানের জন্য বোনাস
                পয়েন্ট প্রদান করা
              </li>
              <li className="main__item">
                প্রথম ডিপোজিট বোনাস 150% - রেজিস্ট্রেশনের 30 দিন পর বৈধ
              </li>

              {/* <li className="main__item">
                100% first deposit bonus - valid 30 days after registration
              </li> */}
            </>
          )}
        </ul>
        <button
          type="button"
          className="show__button"
          onClick={() => {
            setShowDescription((prevState) => !prevState);
          }}
        >
          {showDescription ? "কম" : "অধিক তথ্য..."}
        </button>
        <div className="line"></div>
        <Reviews />
        <div className="line"></div>
        <New />
        <div className="line"></div>
        <Additional />
        <div className="line"></div>
      </main>
      <a style={{ opacity: "0" }} href="#" id="r">
        test
      </a>
    </div>
  );
};
