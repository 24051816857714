export const Additional = () => {
  return (
    <div className="additional">
      <p className="additional__title">অধিক তথ্য</p>
      <div className="additional__wrapper additional__wrapper--mobile">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">আপডেট করা হয়েছে</p>
            <p className="additional__description">19.04.24</p>
          </div>

          <div className="additional__item">
            <p className="additional__text">প্রতিষ্ঠিত</p>
            <p className="additional__description">127544</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">বয়স</p>
            <p className="additional__description">18+</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">আকার</p>
            <p className="additional__description">3.2MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">বর্তমান সংস্করণ</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">বিকাশকারী</p>
            <p className="additional__description">পাগল সময় ক্যাসিনো</p>
          </div>
        </div>
      </div>
      <div className="additional__wrapper additional__wrapper--desktop">
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">আপডেট করা হয়েছে</p>
            <p className="additional__description">19.04.24</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">আকার</p>
            <p className="additional__description">3.2MB</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">প্রতিষ্ঠিত</p>
            <p className="additional__description">127544</p>
          </div>
        </div>
        <div className="additional__thumb">
          <div className="additional__item">
            <p className="additional__text">বর্তমান সংস্করণ</p>
            <p className="additional__description">2.2</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">বয়স</p>
            <p className="additional__description">18+</p>
          </div>
          <div className="additional__item">
            <p className="additional__text">বিকাশকারী</p>
            <p className="additional__description">পাগল সময় ক্যাসিনো</p>
          </div>
        </div>
      </div>
    </div>
  );
};
